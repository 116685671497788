<script>
import { mapGetters, mapMutations } from 'vuex'

import { pathWithIdToPathWithName, findField, sortByPath, getAgGridRenderer } from '@/shared'
import { AgGridVue } from "ag-grid-vue";
import btnRenderer from '@/views/components/ag-grid/btn-renderer'; 
import yesnoRenderer from '@/views/components/ag-grid/yesno-renderer'; 
import dropdownRenderer from '@/views/components/ag-grid/dropdown-renderer'; 
import dateRenderer from '@/views/components/ag-grid/date-renderer'; 
import timeRenderer from '@/views/components/ag-grid/time-renderer'; 
import datetimeRenderer from '@/views/components/ag-grid/datetime-renderer'; 
import numberRenderer from '@/views/components/ag-grid/number-renderer'; 
import priceRenderer from '@/views/components/ag-grid/price-renderer'; 
import addressRenderer from '@/views/components/ag-grid/address-renderer'; 
import phonenumberRenderer from '@/views/components/ag-grid/phonenumber-renderer'; 
import emailRenderer from '@/views/components/ag-grid/email-renderer'; 
import stringRenderer from '@/views/components/ag-grid/string-renderer'; 
import tagsRenderer from '@/views/components/ag-grid/tags-renderer'; 
import userRenderer from '@/views/components/ag-grid/user-renderer'; 
import usergroupRenderer from '@/views/components/ag-grid/usergroup-renderer'; 
import fileRenderer from '@/views/components/ag-grid/file-renderer'; 
import objectRenderer from '@/views/components/ag-grid/object-renderer'; 
import objectListRenderer from '@/views/components/ag-grid/object-list-renderer'; 
import columnHeaderRenderer from '@/views/components/ag-grid/column-header-renderer.vue'; 
import linkRenderer from '@/views/components/ag-grid/link-renderer.vue'; 
import pipelineRenderer from '@/views/components/ag-grid/pipeline-renderer'; 

export default {
  name: "object-list-renderer",
  components: {
    AgGridVue,
    btnRenderer: btnRenderer,
    yesnoRenderer: yesnoRenderer,
    dropdownRenderer: dropdownRenderer,
    dateRenderer: dateRenderer,
    timeRenderer: timeRenderer,
    datetimeRenderer: datetimeRenderer,
    numberRenderer: numberRenderer,
    priceRenderer: priceRenderer,
    addressRenderer: addressRenderer,
    phonenumberRenderer: phonenumberRenderer,
    emailRenderer: emailRenderer,
    stringRenderer: stringRenderer,
    tagsRenderer: tagsRenderer,
    userRenderer: userRenderer,
    usergroupRenderer: usergroupRenderer,
    fileRenderer: fileRenderer,
    'ag-grid-object-renderer': objectRenderer,
    'ag-grid-object-list-renderer': objectListRenderer,
    pipelineRenderer: pipelineRenderer,
    columnHeaderRenderer: columnHeaderRenderer,
    linkRenderer: linkRenderer
  },
  props: {
    dropdown: { //placed in dropdown list (object-input)
      type: Boolean,
      default: false
    },
    entityId: {
      type: String,
      required: true
    },
    displayFields: {
      type: Array,
      default: () => []
    },
    onGrid: {
      type: Boolean
    },
    value: {
      type: Array
    },
    noCard: {
      type: Boolean
    },
    allowOpen: {
      type: Boolean,
      default: false
    },
    allowNew: {
      type: Boolean,
      default: false
    },
    
    fkFieldName: {
      type: String,
      required: false
    },
    fkObject: {
      type: Object,
      required: false
    },
  },
  computed: {
    ...mapGetters('configuration', {
      entities: 'entities',
      fieldMap: 'fieldMap',
      objectForms: 'objectForms'
    }),
    entityForm() {
      return this.objectForms.find(x => x.entity === this.entityId && this.$can('available', x.id));
    },
    sortedItems() {
      const sortBy = this.sortBy;
      return sortByPath(this.entity, this.value ? [...this.value] : [], sortBy);
    },
    entity() {
      return this.entities.find(x => x.id === this.entityId);
    },
    defaultDisplayField() {
      return this.entity.fields.find(x => x.id === this.entity.displayValueFieldId);
    },
    columnDefs() {
      const source = this.displayFields?.length > 0 ? this.displayFields : (this.defaultDisplayField ? [{field: this.defaultDisplayField.id }] : [] );
      let columns = [];
      if(source.length === 0) {
        columns.push({ 
          colId: 'DisplayValue',
          headerName: "", 
          field: "DisplayValue" , 
          pinned: false, 
          resizable: true, 
          hide: false,
          suppressMovable:false,
        });
      } else {
        source.forEach(sourceField => {

          const path = pathWithIdToPathWithName(sourceField.field);
          const fieldInfo = findField(this.entity, sourceField.field);
          const rendererInfo = getAgGridRenderer(this.entity, sourceField.field);
          const { cellRenderer, params } = rendererInfo;
          columns.push({ 
            colId: sourceField.field,
            headerName: sourceField.label || fieldInfo.field.displayName, 
            field: path, 
            pinned: false, 
            resizable: true, 
            hide: false,
            width: sourceField.width || this.getMinColumnWidth(fieldInfo.field.type),
            suppressMovable:false,
            cellRenderer: cellRenderer,
            cellRendererParams: params,
          
          });
        });
      }

      if(!this.dropdown) {
        if(this.entityForm && this.allowOpen) {
          columns = [{ 
            headerName: "", 
            field: "Id" , 
            width:  40, 
            pinned: false, 
            hide: false, 
            suppressNavigable: true,
            cellClass: 'dropdown-button-cell ag-btn-cell',
            cellRenderer: 'linkRenderer',
            cellRendererParams: {
              onClick: (item) => {
                const formParams = {
                  mode: 'View',
                  entity: this.entity,
                  objId: item.Id,
                  reload: async () => {
                    this.$emit('reload');
                  }
                };
                this.showForm(formParams);
              } 
            },
          }, ...columns];
        }
      }
      
      return columns;
    },
    sortBy() {
      const source = this.displayFields?.length > 0 ? this.displayFields : (this.defaultDisplayField ? [{field: this.defaultDisplayField.id }] : [] );
      if(source.length === 0) {
        return 'DisplayValue';
      } else {
        const path = pathWithIdToPathWithName(source[0].field);
        return path;
      }
    },
    dynamicHeight() {
      return this.sortedItems.length < 5;
    }
  },
  methods: {
    ...mapMutations('ui', {
      showForm: 'showForm'
    }),
    getMinColumnWidth(type) {
      if(type === 'Address') return 220;
      if(type === 'YesNo') return 60;
      if(type === 'Date') return 110;
      if(type === 'Time') return 90;
      if(type === 'DateTime') return 150;
      if(type === 'String') return 120;
      if(type === 'Reference') return 150;
      if(type === 'PhoneNumber') return 230;
      if(type === 'Number') return 70;
      if(type === 'Price') return 110;
      if(type === 'User') return 150;
      if(type === 'UserGroup') return 150;
      if(type === 'Email') return 150;
      if(type === 'File') return 150;
      return 80;
    },
    onNew() {
      const template = {};
      template[this.fkFieldName] = this.fkObject;
      const hidden = {};
      hidden[this.fkFieldName] = true;
      const formParams = {
        mode: 'New',
        entity: this.entity,
        template,
        hidden,
        reload: async () => {
         
          this.$emit('reload');
        }
      };
      this.showForm(formParams);
    },
    onRowClicked(args) {
      this.$emit('item-click', args.data)
    }
  }
};
</script>

<template>
  <div v-if="onGrid" class="position-relative">
    <div v-if="sortedItems && sortedItems.length > 0">
      <div  v-if="displayFields.length > 0">
        <span v-for="obj in sortedItems" :key="obj.id" class="d-inline-block mr-2">
          <object-renderer 
            :no-card="true"
            :entity-id="entityId"
            :value="obj"          
            :display-fields="[displayFields[0]]"
            :allowOpen="allowOpen"
          >
          </object-renderer>
        </span>
      </div>
      <div v-else>
        <span v-for="obj in sortedItems" :key="obj.id" class="d-inline-block mr-2">
          <object-renderer 
            :no-card="true"
            :entity-id="entityId"
            :value="obj" 
            :hide-label="true"
            :allowOpen="allowOpen"
          >
          </object-renderer>
        </span>
      </div>
    </div>
  </div> 
  <div v-else >
    <div v-if="(displayFields.length === 0)">
      <div class="d-flex justify-content-end" v-if="entityForm && allowNew && fkFieldName && fkObject"  >
        <button class="btn btn-sm btn-light mb-50"
          @click="onNew"
        >
          <feather-icon
            class="mr-50"
            icon="PlusIcon"
          />
          {{$t('common.create-button-title')}}
        </button>
      </div>
      <span v-for="obj in sortedItems" :key="obj.id" class="d-inline-block mr-2">
        <object-renderer 
          :no-card="noCard || onGrid"
          :entity-id="entityId"
          :value="obj" 
          :display-fields="defaultDisplayField ? [{field: defaultDisplayField.id, hiddenLabel: true}] : null"
          :allowOpen="allowOpen"
        >
        </object-renderer>
      </span>
    </div>
    <div v-else class="card no-body p-25 mb-1">
      <div class="d-flex justify-content-end" v-if="entityForm && allowNew && fkFieldName && fkObject"  >
        <button class="btn btn-sm btn-light mb-50"
          @click="onNew"
        >
          <feather-icon
            class="mr-50"
            icon="PlusIcon"
          />
          {{$t('common.create-button-title')}}
        </button>
      </div>
      <ag-grid-vue
        ref="table"
        :enableCellTextSelection="true"
        :suppressCellFocus="true"
        :ensureDomOrder="true"
        class="ag-theme-alpine object-list-ag-grid no-drag-area"
        :class="{'dropdown': dropdown}"
        :columnDefs="columnDefs"
        :rowData="sortedItems"
        :animateRows="true"
        :domLayout="dropdown ? 'normal' : 'autoHeight'"
        headerHeight="30"
        @rowClicked="onRowClicked"
      >
      </ag-grid-vue>
    </div>

  </div>
</template>


<style lang="scss" scoped>
  @import "~ag-grid-community/styles/ag-grid.css";
  @import "~ag-grid-community/styles/ag-theme-alpine.css";

  
  .object-list-ag-grid {
    width:100%;
    
    &.dropdown{
      height: 250px;
    }

   &.fix-height {
    height: 30vh;
    max-height: 30vh;
    overflow: auto;

   }


   .ag-root-wrapper{
      border: none;
    }
    .ag-row {
      border-bottom: 0;
    }
  }
</style>

