<script>

import gmapsInit from '@/gmaps';
import {
  BModal
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null,
      modalShow: false,
      latlng: ''
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  components: {  
    BModal
  },
  async mounted() {
    try {
      const google = await gmapsInit();
      this.google = google;
      const options = {
        language: this.language,
        // componentRestrictions: { country: "ua" },
        fields: [ "geometry", "formatted_address"]
      };
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, options);
      this.autocomplete = autocomplete;
      this.autocomplete.addListener('place_changed', this.googlePlaceChanged);
    } catch (error) {
      console.error(error);
    }
  },
  beforeUnmount(){
    if(this.google) {
      if(this.autocomplete) {
        this.google.maps.event.clearInstanceListeners(this.$refs.autocompleteInput)
      }
      this.clear();
    }
  },
  computed: {
    ...mapGetters('account', {
      language: 'language',
      mapCenter: 'mapCenter'
    })
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    clear() {
      if(this.marker) {
        this.google.maps.event.clearListeners(this.marker);
        this.marker = null;
      }
      this.latlng = null;
    },
    initMap() {
      console.log('init map', this.mapCenter);
      const center = { lat: this.p_value.Lat || this.mapCenter.lat, lng: this.p_value.Lng || this.mapCenter.lng };
      let map = new  this.google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 14,
      });
    
      this.map = map;

      const uluru = { lat: this.p_value.Lat || center.lat, lng: this.p_value.Lng || center.lng };
      // The marker, positioned at Uluru
      const marker = new this.google.maps.Marker({
        position: uluru,
        map: map,
        draggable: true,
      });
      this.marker = marker;
      marker.addListener("dragend", this.markerDragend);
    },
    markerDragend(e) {
      this.p_value.Lat = e.latLng.lat();
      this.p_value.Lng = e.latLng.lng();
    },
    googlePlaceChanged() {
      var place = this.autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // Do anything you like with what was entered in the ac field.
        //console.log('You entered: ', { place, r: this.autocomplete.removeListener });
        return;
      }

      //console.log('You selected: ', { place, r: this.autocomplete.removeListener });
      const newValue = {
        Lat: place.geometry.location.lat(),
        Lng: place.geometry.location.lng(),
        Value: place.formatted_address
      };
      this.p_value = newValue;
      this.onChange();
      //console.log('You selected: ', { newValue });
      //this.$emit('update', val); 
    },
    updateInternalValue() {
      this.p_value = this.value || {
        Value: null,
        Lat: null,
        Lng: null
      };
    },
    onChange() {
      this.$emit('input', this.p_value);
    },
    onBlur(v) {
      this.updateAddressString(v);
      this.$emit('blur');
    },
    updateAddressString(v) {
      this.p_value.Value = v;
      this.onChange();
    },
    onModalShow() {
      this.modalShow = true;
      this.latlng = null;
    },
    searchLatLng() {
      if (!this.latlng) return;

      // Разделите введенную строку на широту и долготу
      const coords = this.latlng.split(',').map(coord => parseFloat(coord.trim()));

      // Создайте новый объект LatLng
      const position = new this.google.maps.LatLng(coords[0], coords[1]);

      // Переместите маркер на новые координаты
      this.marker.setPosition(position);

      // Переместите центр карты на новые координаты
      this.map.setCenter(position);

      // Обновите значения широты и долготы в вашем состоянии
      this.p_value.Lat = coords[0];
      this.p_value.Lng = coords[1];
      this.onChange();
    }
  }
};
</script>

<template>
  <div style="position: relative;
    flex: 1 1 auto;
    ">

    <b-modal
      v-model="modalShow"
      
      ok-only
      :ok-title="$t('common.close-button-title')"
      @shown="initMap"
      @hidden="clear"
      @ok="onChange"
    >
      <div class="form-group">
        <input class="form-control" v-model="p_value.Value"/>
      </div>
      <div class="form-group">
        <b-input-group class="input-group">
          <b-form-input
            autocomplete="off"
            v-model="latlng"
            placeholder="Lat & Lng"
          />
          <b-input-group-append >
            <b-button @click="searchLatLng">
              <feather-icon
                icon="SearchIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div ref="map" style="height:400px"></div>
    </b-modal>

    <div style="position: relative;">
      <input 
        type="text" 
        style="padding-left: 35px;"
        :value="p_value.Value"  
        @input.stop="event => updateAddressString(event.target.value)" 
        @blur.stop="event => onBlur(event.target.value)"
        class="form-control" 
        :class="{'is-invalid':  invalid}"
        ref="autocompleteInput"
      />
      <a href="javascript:void(0)" class="pl-1" style="position: absolute; top:7px; left:0px" @click="onModalShow">
        <feather-icon icon="EditIcon"/>
      </a>
    </div>
  </div>
  
</template>

<style lang="scss" scoped>
 
</style>
  